import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class ServiceBR12Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BR12"
          description="View route information and buy your ticket for the BR12 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BR12 service</h1>
              <p>Methley/Mickletown Road - Brigshaw High School</p>
              <p>Brigshaw High School – Methley/Mickletown Road</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
              <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="BR12 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=13OAWvnS1_mI1VYqNpSvYNAzu69SxXBk&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Brigshaw High School
                </p>
                <p>
                  From Mickletown Road/Savile Road via Mickletown Road, Savile Road, Pinfold Lane, Barnsdale Road, Methley Road, Savile Road, Aire Street, Lock Lane, Barnsdale Road, Station Road, Leeds Road, Preston Lane, Brigshaw Lane.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="BR12 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=125EHOH1H9D2QCW8OVkZgEfePVirinMo&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Brigshaw High School
                </p>
                <p>
                  From Brigshaw Lane, Preston Lane, Leeds Road, Station Road, Barnsdale Road, Lock Lane, Aire Street, Savile Road, Methley Road, Barnsdale Road, Pinfold Lane, Main Street and Mickletown Road.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mickletown Road/Savile Road</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Methley Road/Bryan Close</td>
                      <td>DEP</td>
                      <td>0800</td>
                    </tr>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>ARR</td>
                      <td>0820</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Brigshaw High School</td>
                      <td>DEP</td>
                      <td>1510</td>
                    </tr>
                    <tr>
                      <td>Methley Road/Bryan Close</td>
                      <td>DEP</td>
                      <td>1525</td>
                    </tr>
                    <tr>
                      <td>Mickletown Road</td>
                      <td>ARR</td>
                      <td>1540</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceBR12Page
